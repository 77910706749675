import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination])

export default class extends Controller {
  static targets = ['next', 'prev', 'swiperContainer']

  connect() {
    new Swiper(this.swiperContainerTarget, {
      loop: true,
      breakpoints: {
        100: {
          slidesPerView: 1,
          spaceBetween:14
        },
        640: {
          slidesPerView: 1.5,
          spaceBetween: 14
        },
        768: {
          slidesPerView: 2.5,
          spaceBetween: 14
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 14,
        },
        1280: {
          slidesPerView: 3.5,
          spaceBetween: 14
        }
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget
      },
    })
  }
}
