import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// Makes the initMap function Google's API requires
// available in Stimulus controllers
window.initMap = function (...args) {
  const event = document.createEvent('Events')
  event.initEvent('google-maps-callback', true, true)
  event.args = args
  window.dispatchEvent(event)
}
