import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
    filter() {
        const curr_url = window.location;
        const form = $(this.element);
        const select = form.find('#content_cat').val().toLowerCase();
        const new_url = curr_url.href + '?c=' + select;
        location.href = new_url;
    }
}