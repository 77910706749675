import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    if (typeof google !== 'undefined') {
      this.initMap()
    }
  }

  initMap() {
    let map
    let bounds = new google.maps.LatLngBounds()
    let markers = []
    let startPosition = { lat: 51.857068116622024, lng: 0.8182847217122546 }

    map = new google.maps.Map(this.containerTarget, {
      center: { lat: 34.397, lng: 150.644 },
      zoom: 10,
    });

    let icon = {
      url: this.iconURL,
      scaledSize: new google.maps.Size(35, 35), // scaled size
      origin: new google.maps.Point(0,0), // origin
      anchor: new google.maps.Point(0, 0) // anchor
    };

    for (let location of this.mapLocations) {
      let marker
      let markerPosition = { lat: location.position.lat, lng: location.position.lng }
      let contentString = this.generateContent(location)

      let infowindow = new google.maps.InfoWindow({
        content: contentString,
      })

      marker = new google.maps.Marker({
        position: markerPosition,
        map: map,
        title: location.mailing_name,
        icon: icon
      })

      marker.addListener('click', function () {
        infowindow.open(map, marker)
      })

      markers.push(marker)

      bounds.extend(markerPosition)
      marker.setMap(map)
    }

    map.fitBounds(bounds)
    map.setZoom(5)

  }

  generateContent(location) {
    return `<div id='content' class='p-2 space-y-2'>
        <h1 class='text-xl font-semibold'>${location.name}</h1>
        <p>${location.locationAddress}</p>
        <a href='${location.linkPath}' class='inline-block underline hover:no-underline'>Go to location</a>
      </div>`
  }

  get mapLocations() {
    return JSON.parse(this.containerTarget.dataset.markers)
  }

  get iconURL() {
    return this.containerTarget.dataset.icons
  }
}
